<template>
    <b-modal size="md" ref="changeBuyerModal" :title="modalTitle">
        <div>
            <b-alert v-if="bill.invoice_is_issued" show variant="warning" class="p-1 text-center">
                {{$t('general.no_change_buyer_text')}}
            </b-alert>
            <b-form-group v-else :label="$t('general.buyer')">
                <v-select v-model="selectedBuyerId" appendToBody label="name" :options="buyers" :reduce="item => item.id" :loading="loading" :clearable="!isDeliveryNote">
                    <template #no-options>
                        {{$t('graphs.no_data')}}
                    </template>
                </v-select>
            </b-form-group>
        </div>
        <template #modal-footer>
            <div class="d-flex">
                <b-button variant="secondary" @click="$refs.changeBuyerModal.hide()">{{$t('general.cancel')}}</b-button>
                <b-button v-if="!bill.invoice_is_issued" class="ml-1" variant="primary" :disabled="loadingOnChange" @click="changeBuyer">
                    {{$t('general.save')}}
                    <b-spinner v-if="loadingOnChange" class="ml-1" style="height: 15px; width: 15px"/>
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import {BModal, BButton, BFormGroup, BAlert, BSpinner} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import vSelect from 'vue-select'

    export default {
        components: {BSpinner, BAlert, BModal, BButton, BFormGroup, vSelect},
        props: {
            bill: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                loadingOnChange: false,
                showModal: false,
                selectedBuyerId: null,
                buyers: []
            }
        },
        methods:{
            show() {
                if (this.bill.buyer) this.selectedBuyerId = this.bill.buyer.id
                else this.selectedBuyerId = null
                this.loadingOnChange = false
                this.$refs.changeBuyerModal.show()
            },
            async loadData() {
                try {
                    this.loading = true
                    const response = await this.$http.get('/api/client/v1/buyers')
                    this.buyers = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.loading = false
                }
            },
            async changeBuyer() {
                try {
                    this.loadingOnChange = true

                    let selectedBuyerId = ''
                    if (this.selectedBuyerId) selectedBuyerId = this.selectedBuyerId

                    const payload = {
                        issued_bill_id: this.bill.id,
                        buyer_id: selectedBuyerId
                    }

                    await this.$http.post('/api/client/v1/issued_bills/change_buyer', payload)
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.$emit('changed')
                    this.$refs.changeBuyerModal.hide()
                } catch (err) {
                    Sentry.captureException(err)
                    if (err && err.response && err.response.data && (err.response.data.error === 'own consumption must not have an added customer')) {
                        this.$printWarning(this.$t('general.error_own_consumption_must_not_have_added_customer'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'delivery note must have a buyer')) {
                        this.$printWarning(this.$t('general.error_delivery_note_must_have_buyer'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'issued bill history with this uuid and change index already exists')) {
                        this.$printWarning(this.$t('general.error_issued_bill_history_already_exists'))
                    }  else if (err && err.response && err.response.data && (err.response.data.error === 'issued bill is reversal')) {
                        this.$printWarning(this.$t('general.error_issued_bill_is_reversal'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'buyer already exists on issued bill')) {
                        this.$printWarning(this.$t('general.error_buyer_already_exists_on_issued_bill'))
                    } else if (err && err.response && err.response.data && (err.response.data.error === 'bill has not yet been send to furs')) {
                        this.$printWarning(this.$t('general.error_bill_has_not_yet_been_send_to_furs'))
                    } else this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.loadingOnChange = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        },
        computed: {
            modalTitle() {
                return this.bill.buyer ? this.$t('general.change_buyer_on_bill') : this.$t('general.add_buyer_to_bill')
            },
            isDeliveryNote() {
                return this.bill && this.bill.payment_types && this.bill.payment_types.length > 0 &&
                    this.bill.payment_types[0].payment_type === 2 // dobavnica
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>